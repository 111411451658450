import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { episode } from '../types';
import { Button, Table } from 'react-bootstrap';

type props = {
    search: string,
    show: string,
    onAssign: (id: string) => void
}

const MatchingEpisodes = ({search, show, onAssign}: props) => {
    const [matches, setMatches] = useState<Array<episode>>([]);

    useEffect(() => {
        (async () => {
            const params = new URLSearchParams();
            params.append('name_contains', search);
            params.append('season.show', show);
            let result = await axios(`/api/episodes/?${params}`);
            setMatches(result.data);
        })();
    }, [search, show]);

    return(
        <>
            <h3>Matches</h3>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {matches.map(el => {
                        return <tr key={el.id}><td>{el.downloaded ? <del>{el.name}</del> : el.name}</td><td><Button onClick={() => onAssign(el.id)}>Assign</Button></td></tr>;
                    })}
                </tbody>
            </Table>
        </>
    );
};

export default MatchingEpisodes;