import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { download } from '../../types';
import { Table } from 'react-bootstrap';
import { isEpisode } from '../../helpers';

const Downloads = () => {
    const [downloads, setDownloads] = useState<Array<download>>([]);
    const [filterIgnored, setFilterIgnored] = useState(true);
    const [filterDownloaded, setFilterDownloaded] = useState(true);

    useEffect(() => {
        (async () => {
            setDownloads((await axios("/api/downloads")).data);
        })();
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch(event.target.name) {
            case "ignored":
                setFilterIgnored(event.target.checked);
                break;
            case "downloaded":
                setFilterDownloaded(event.target.checked);
                break;
            default:
        }
    };

    return (
        <div>
            <h1>Downloads</h1>
            <input type="checkbox" checked={filterIgnored} name="ignored" onChange={handleChange} /> Hide ignored{' '}
            <input type="checkbox" checked={filterDownloaded} name="downloaded" onChange={handleChange} /> Hide downloaded
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Matched</th>
                        <th>Downloaded</th>
                    </tr>
                </thead>
                <tbody>
                    {downloads.filter(el => {
                        if(filterIgnored && el.ignore) {
                            return false;
                        }
                        if(filterDownloaded && isEpisode(el.episode) && el.episode.downloaded) {
                            return false;
                        }
                        return true;
                    }).map(el => {
                        return(
                            <tr key={el.id}>
                                <td><Link to={`/downloads/${el.id}`}>{el.ignore ? <del>{el.name}</del> : el.name}</Link></td>
                                <td>{el.matched ? "x": ""}</td>
                                <td>{el.matched && isEpisode(el.episode) ? (el.episode.downloaded ? 'x' : '') : ''}</td>
                            </tr>
                            );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default Downloads;