import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { show } from '../../types';
import { Table } from 'react-bootstrap';

const Shows = () => {
    const [shows, setShows] = useState<Array<show>>([]);

    useEffect(() => {
        (async () => {
            setShows((await axios("/api/shows")).data);
        })();
    }, [])

    return (
        <div>
            <h1>Shows</h1>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {shows.map(el => {
                        return <tr><td><Link to={`/shows/${el.id}`}>{el.name}</Link></td></tr>;
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default Shows;