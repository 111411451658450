import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { download } from '../../types';
import { isEpisode } from '../../helpers';
import MatchingEpisodes from '../../components/matchingEpisodes';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';

const Details = () => {
    let { id } = useParams();
    const [download, setDownload] = useState<download|null>(null);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        (async () => {
            let result = await axios(`/api/downloads/${id}`);
            setDownload(result.data);
        })();
    }, [id]);

    if(download===null) {
        return(
            <>Loading</>
        )
    }

    const assign = (episodeId: string) => {
        download.matched = true;
        download.episode = episodeId;
        
        axios.put(`/api/downloads/${id}`, download).then((res) => {
            setDownload(res.data);
        });
    };

    const ignore = () => {
        download.ignore = true;

        axios.put(`/api/downloads/${id}`, download).then((res) => {
            setDownload(res.data);
        });
    };

    const markDownloaded = () => {
        if(download.matched && isEpisode(download.episode)) {
            download.episode.downloaded = true;

            axios.put(`/api/episodes/${download.episode.id}`, download.episode).then((res) => {
                const temp = {...download};
                temp.episode = res.data;
                setDownload(temp);
            });
        }
    };

    const doDownload = () => {
        if(download.matched && isEpisode(download.episode)) {
            (async () => {
                setDownloading(true);
                await axios(`/api/downloads/${id}/download`);
                setDownloading(false);
                markDownloaded();
            })();
        }
    };

    let body;
    if(download.matched) {
        body = <p>Matched to: {download.episode.toString()} </p>
    } else {
        body = <MatchingEpisodes search={download.name} show={download.show.id} onAssign={assign} />;
    }

    let buttons = [];
    if(!download.ignore) {
        buttons.push(<Button onClick={ignore}>Ignore</Button>);
    }
    if(!download.ignore && download.matched && isEpisode(download.episode) ) {
        if(download.episode.downloaded) {
            buttons.push(<Button variant="primary" disabled>Downloaded</Button>);
        }
        if(!download.episode.downloaded) {
            if(downloading) {
                buttons.push(
                    <Button variant="primary" disabled>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        Downloading...
                    </Button>
                );
            }
            if(!downloading) {
                buttons.push(<Button variant="primary" onClick={doDownload}>Download</Button>);
                buttons.push(<Button onClick={markDownloaded}>Mark as downloaded</Button>);
            }
        }
    }

    return (
        <>
            <h2>{download.show.name}</h2>
            <h1>{download.ignore ? <del>{download.name}</del> : download.name}</h1>
            <ButtonGroup>{buttons}</ButtonGroup>
            {body}
        </>
    );
};

export default Details;