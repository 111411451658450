import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import axios from 'axios';
import { episode, season, show } from '../../types';

const Details = () => {
    let { id } = useParams();
    const [episodes, setEpisodes] = useState<Array<episode>>([]);
    const [show, setShow] = useState<show>({ name: '', id: '' });

    useEffect(() => {
        (async () => {
            let result = await axios(`/api/seasons/?show=${id}`);
            let eps: Array<episode> = [];
            for (let i = 0; i < result.data.length; i++) {
                const new_eps = result.data[i].episodes.map(function (this: season, ep: episode) {
                    ep.season = this.number;
                    return ep;
                }, result.data[i])
                eps = [...eps, ...new_eps];
            }
            setEpisodes(eps);
        })();
        (async () => {
            let result = await axios(`/api/shows/${id}`);
            setShow(result.data);
        })();
    }, [id]);

    const columns: Handsontable.ColumnSettings[] = [
        { data: '_id', readOnly: true},
        { data: 'name', readOnly: true },
        { data: 'season', className: 'htRight', readOnly: true },
        { data: 'number', className: 'htRight', readOnly: true },
        { data: 'downloaded', type: 'checkbox', className: 'htCenter' }
    ];

    const colHeaders = [
        'ID',
        'Name',
        'Season',
        'Episode',
        'Downloaded'
    ];

    const multiColumnSorting: Handsontable.multiColumnSorting.Settings = {
        initialConfig: [
            { column: 2, sortOrder: 'asc' },
            { column: 3, sortOrder: 'asc' }
        ]
    };

    let ref: any;

    const afterChange = (changes: Handsontable.CellChange[] | null) => {
        changes && changes.forEach(([row, prop, oldValue, newValue]) => {
          // Some logic...
          console.log(`${row},${prop} changed from ${oldValue} to ${newValue}`);
          console.log(episodes[row]);
          console.log(ref.hotInstance.getDataAtRow(row));
        });
    }

    const setRef = (el: any) => {
        console.log(el);
        ref = el;
    }

    return (
        <>
            <h1>{show.name}</h1>
            <HotTable ref={setRef} data={episodes} afterChange={afterChange} multiColumnSorting={multiColumnSorting} licenseKey='non-commercial-and-evaluation' colHeaders={colHeaders} columns={columns} />
        </>
    );
};

export default Details;