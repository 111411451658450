import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Details from './pages/shows/details';
import Shows from './pages/shows';
import DownloadsDetails from './pages/downloads/details';
import Downloads from './pages/downloads';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'react-data-grid/dist/react-data-grid.css';
import 'handsontable/dist/handsontable.full.css';

function App() {
  return (
    <Router>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/shows">Shows</Nav.Link>
          <Nav.Link as={Link} to="/downloads">Downloads</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <Container>
      <Row>
        <Col>
            <Switch>
              <Route path="/shows/:id">
                <Details />
              </Route>
              <Route path="/shows">
                <Shows />
              </Route>
              <Route path="/downloads/:id">
                <DownloadsDetails />
              </Route>
              <Route path="/downloads">
                <Downloads />
              </Route>
            </Switch>
        </Col>
      </Row>
    </Container>
    </Router>
  );
}

export default App;
